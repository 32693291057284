<script setup lang="ts">
import * as yup from 'yup';
import { useForm } from 'vee-validate';

// Composables
const { t } = useI18n();
const { resetForm, values, setValues } = useForm({
  validationSchema: yup.object({
    name: yup.string().required(t('global.required_field')),
    area_type: yup.string().required(t('global.required_field')),
    parent_id: yup.number().required(t('global.required_field')),
    site_id: yup.number().required(t('global.required_field')),
    meters: yup.array().of(
      yup.object({
        type: yup.string().required(t('global.required_field')),
        data_source_id: yup.string().required(t('global.required_field')),
      }),
    ),
  }),
  initialValues: {
    meters: [
      {
        type: '',
        data_source_id: '',
      },
    ],
  },
});

// Stores
const { areaTypes } = storeToRefs(areaStore());
const { currentSite } = storeToRefs(adminStore());

// Props
const modal = defineModel<boolean>();
const props = defineProps<{
  siteId: number;
  parentId: number;
}>();

// Computed
const areaTypesAsOptions = computed(() => {
  return areaTypes.value.map((areaType) => ({
    key: areaType.value,
    label: t(`area.area_types.${areaType.value}`),
  }));
});

const areasAsOptions = computed(() => {
  const options =
    currentSite.value?.areas.map((area) => ({
      key: area.id.toString(),
      label: area.name,
    })) ?? [];
  return props.siteId ? options : [];
});

// Methods
const addMeter = () => {
  if (values.meters) {
    setValues({
      meters: [
        ...values.meters,
        {
          type: '',
          data_source_id: '',
        },
      ],
    });
  } else {
    setValues({
      meters: [
        {
          type: '',
          data_source_id: '',
        },
      ],
    });
  }
};

const removeMeter = (index: number) => {
  setValues({
    meters: values.meters.filter((_, i) => i !== index),
  });
};

// Lifecycle
watch(
  () => props.parentId,
  () => {
    resetForm({
      values: {
        parent_id: props.parentId.toString(),
        meters: [
          {
            type: '',
            data_source_id: '',
          },
        ],
      },
    });
  },
);
</script>
<template>
  <nrjx-modal v-model="modal" width="800" :title="$t('area.add_area')">
    <div class="grid grid-cols-2 gap-x-4 gap-y-2">
      <!-- Title -->
      <p class="text-sm font-medium text-gray-600">{{ $t('global.informations') }}</p>
      <p class="text-sm font-medium text-gray-600">{{ $t('global.meters') }}</p>

      <!-- Content -->
      <div class="col-span-1 p-2 bg-gray-50 rounded-lg border border-gray-200 pb-6">
        <nrjx-input-select
          name="parent_id"
          :placeholder="$t('area.select_parent')"
          :label="$t('area.parent')"
          searchable
          disabled
          fit-to-select
          item-key="key"
          item-label="label"
          :items="areasAsOptions"
          class="mb-4"
        />

        <ui-form-input-text
          name="name"
          required
          autocomplete="off"
          :placeholder="$t('area.placeholder_area_name')"
          :label="$t('area.name')"
        />

        <nrjx-input-select
          name="area_type"
          required
          item-key="key"
          item-label="label"
          fit-to-select
          searchable
          :placeholder="$t('area.select_area_type')"
          :label="$t('area.area_type')"
          :items="areaTypesAsOptions"
        />
      </div>
      <div class="col-span-1 p-2 flex flex-col justify-between bg-gray-50 rounded-lg border border-gray-200">
        <div class="space-y-4 max-h-[400px] overflow-y-auto">
          <div v-for="(meter, index) in values.meters" :key="index" class="border border-gray-200 rounded-lg p-2 bg-slate-100">
            <ui-form-input-text
              :label="$t('area.meter_type')"
              :name="`meters[${index}].type`"
              :placeholder="$t('area.placeholder_meter_type')"
            />
            <ui-form-input-text
              :label="$t('area.data_source_id')"
              :name="`meters[${index}].data_source_id`"
              placeholder="rbl/tgbt/052b698, rbl/tgbt/052b699, ..."
            />
            <ui-button v-if="values.meters.length > 1" left-icon="Trash" class="w-full mt-6" color="delete" @click="removeMeter(index)">
              {{ $t('global.delete') }}
            </ui-button>
          </div>
        </div>

        <ui-button left-icon="Plus" class="w-full mt-4" color="secondary" @click="addMeter">
          {{ $t('area.add_meter') }}
        </ui-button>
      </div>
    </div>
  </nrjx-modal>
</template>
