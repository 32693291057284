<script setup lang="ts">
// Import
import AreaModal from '../../../components/modal/AreaModal.vue';

// Composables
const { getSite } = useSiteApi();

// Store
const { currentSite } = storeToRefs(adminStore());

// Data
const isAreaModalOpen = ref(false);
const parentId = ref(0);

// Computed
const siteId = computed(() => currentSite.value?.id);
const areas = computed(() => currentSite.value?.areas ?? []);

// Methods
const openAreaModal = (id: number) => {
  parentId.value = id;
  isAreaModalOpen.value = true;
};

const updateAreas = () => {
  if (siteId.value) {
    getSite(siteId.value);
  }
};
</script>
<template>
  <ui-card>
    <ui-chart-flow :areas="areas" show-add-node-button @add-child="openAreaModal" />

    <!-- Modal -->
    <area-modal v-if="siteId" v-model="isAreaModalOpen" :site-id="siteId" :parent-id="parentId" @update-areas="updateAreas" />
  </ui-card>
</template>
